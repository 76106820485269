import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  title: string
  URL: string
}

export const Item = memo(function Item({ icon, title, URL }: Props) {
  if (!title && !URL) {
    return null
  }

  const buttonDOM = icon ? (
    <>
      <Icon alt={title} src={icon} width="30" height="30" />
      <Title>{title}</Title>
    </>
  ) : null
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return (
    <>
      {externalURL ? (
        <Anchor href={URL} rel="noopener" target="_blank">
          {buttonDOM}
        </Anchor>
      ) : (
        <Button to={URL}>{buttonDOM}</Button>
      )}
    </>
  )
})

const Style = css`
  display: flex;
  align-items: center;
  max-width: 39rem;
  border: 0.375rem solid ${theme.colors.variants.neutralLight4};
  border-radius: 1.625rem;
  margin: 1rem auto 0;
  padding: 1.6875rem 1.875rem;
  transition: 0.2s ease-in-out;
  &:hover {
    border-color: ${theme.colors.variants.primaryDark1};
  }
  &:first-of-type {
    margin-top: 0;
  }
`

const Anchor = styled.a`
  ${Style}
`

const Button = styled(Link)`
  ${Style}
`

const Icon = styled.img`
  width: auto;
  height: 1.875rem;
  margin-right: 1rem;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: uppercase;
`
