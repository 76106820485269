import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const ConciergeIntro = memo(function ConciergeIntro({
  description,
  title,
}: Props) {
  return (
    <Container dial={4} row tag="section" wrap>
      <LeftSide>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
      </LeftSide>
      <RightSide>
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 12.125rem 13.333vw;

  @media (max-width: 1199px) {
    padding-right: 6.039vw;
    padding-left: 6.039vw;
  }

  @media (max-width: 767px) {
    padding: 5.375rem 1.5625rem 6.25rem;
  }
`

const LeftSide = styled.div`
  width: 40%;
  padding-right: 10.486vw;

  @media (max-width: 767px) {
    width: 100%;
    padding-right: 0;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 600;
  line-height: 4rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 3.125rem;
    line-height: 2.875rem;
  }
`

const RightSide = styled.div`
  width: 60%;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 1.25rem;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
`
