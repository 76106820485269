import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  title?: string
}

export const Links = memo(function Links({ items, title }: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <List>
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  margin: 7.75rem auto 9rem;
  padding: 0 3.333vw;

  @media (max-width: 767px) {
    margin: 5.3125rem auto 6.25rem;
    padding: 0 1.5625rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.4375rem;
  font-weight: 600;
  line-height: 3.4375rem;
  margin-bottom: 3.75rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 3.125rem;
    line-height: 2.875rem;
  }

  @media (max-width: 767px) {
    margin-bottom: 2.5rem;
    text-align: left;
  }
`

const List = styled(FadeInUp)``
