import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const VisualCTA = memo(function VisualCTA({ items }: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      {items.map((item, index) => (
        <Item key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section``
